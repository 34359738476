@tailwind base;
@tailwind components;
@tailwind utilities;

@media screen and (min-width: 1600px) {
  :root {
    font-size: 16px !important;
  }
}
@media screen and (min-width: 1380px) and  (max-width: 1599px) {
  :root {
    font-size: 13px !important;
  }
}
@media screen and (min-width: 1025px) and  (max-width: 1379px) {
  :root {
    font-size: 11px !important;
  }
}

@media screen and (max-width: 1024px) {
  :root {
    font-size: 13px !important;
  }
}

body {
  // background-image: linear-gradient(to top, #d6dceb 0%, #c5c6c7 100%);
  background-color: #f2f2f2;
  font-family: 'Poppins';
 
}

a{
  text-decoration: none !important;
}

.spaceName {
  font-size: 18px;
  font-weight: 600;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 10px;
}
.loadding {
  padding: 20px;
}

.main-container {
  // font-family: "Inter", sans-serif;
  background-color: #f2f2f2;
  width: 100%;
  height: 100vh;
  // padding: 0px 0px 0px 70px;
  transition: all 0.5s ease;
}

.sidebar {
  position: fixed;
  left: 0;
  height: 100%;
  width: 70px;
  // background: #fff;
  z-index: 100;
  transition: all 0.5s ease;
  // box-shadow: 0px 4px 4px 0px #00000040;
  padding: 0 10px;

  ul.nav-list {
    padding: 1rem 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    list-style: none;
  }

  & li .side-tooltip {
    position: absolute;
    top: 0;
    left: calc(100% + 15px);
    z-index: 3;
    background-color: #ffffff;
    color: #4444f5;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
    padding: 0 20px;
    font-size: 15px;
    font-weight: 500;
    border-radius: 5px;
    white-space: nowrap;
    opacity: 0;
    pointer-events: none;
    line-height: 3rem;
  }
  & li:hover .side-tooltip {
    opacity: 1;
    pointer-events: auto;
    transition: all 0.5s ease;
  }
  .nav-items-wrap {
    padding: 20px 0;
  }
  .nav-items {
    position: relative;
    font-weight: 600;
    font-size: 1rem;
    padding: 0.5rem 0;
  }
  .nav-items a {
    color: #fff;
    transition: ease 0.4s;
  }
  .bar-img-wrap {
    // min-width: 50px;
    text-align: center;
    color: #5858f2;
    background-color: rgba(#5858f2, 0.1);
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: ease 0.4s;
    i {
      font-size: 1.5rem;
    }
  }
  .nav-items a svg {
    width: 26px;
  }
  .navigation-apps a svg {
    width: 18px;
  }
  .showNav .link_name {
    opacity: 1;
    transition: all 0.5s ease;
  }
  .link_name {
    opacity: 0;
    padding-left: 12px;
  }
  .nav-items a:hover,
  .nav-items .active {
    transition: all 0.5s ease;
    .bar-img-wrap {
      color: #d6d6f5;
      background-color: rgba(#5858f2, 1);
    }
  }
}

.navToggolForMobileBAckSkin {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9;
  background-color: rgba(#000000, 0.4);
  transition: ease 0.3s;
  &.hide {
    opacity: 0;
    visibility: hidden;
  }
}
.navToggolForMobile {
  position: fixed;
  right: 1rem;
  top: 1rem;
  z-index: 8;
  color: #fff;
  opacity: 0.8;
}

@media screen and (max-width: 767px) {
  .main-container {
    padding: 0;
    .sidebar {
      opacity: 0;
      left: -70px;
      background-color: #fff;
    }
    &.mobileView {
      padding: 0px 0px 0px 70px;
      .sidebar {
        opacity: 1;
        left: 0;
      }
    }
  }
}
