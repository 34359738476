.dropdown-toggle {
  padding: 0;
  border: 0;
  background-color: transparent;
  border-radius: 50%;
  cursor: pointer;
}
.dropdown-menu-head {
  position: absolute;
  top: 110%;
  right: 1rem;
  margin: 0;
  background: #fff;
  box-shadow: 2px 2px 5px 1px #ccc;
  border-radius: 2rem;
  transition-property: transform, opacity;
  transition-duration: 150ms;
  transition-timing-function: ease;
  transform-origin: right top;
  opacity: 0;
  transform: scale(1);
  padding: 1.3rem 2.5rem;
  z-index: 3;
  visibility: hidden;
}

.dropdown-menu-head li {
  text-decoration: none;
  list-style: none;
}
.dropdown-menu-head li img {
  padding-right: 10px;
}

.dropdown-menu-head.open {
  transition-property: transform, opacity;
  transition-duration: 150ms;
  transition-timing-function: ease;
  transform-origin: right top;
  opacity: 1;
  transform: scale(1);
  visibility: visible;
}

.profile-wrap {
  cursor: pointer;
  border-radius: 5px;
  backdrop-filter: blur(10px);
  text-transform: capitalize;
  margin-left: 10%;
}

.profile-img-wrap {
  width: 42px;
  height: 37px;
  cursor: pointer;
}
.profile-img-wrap img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
}

.profile-name {
  font-size: 16px;
  font-weight: 600;
  margin-left: 10px;
}
.profile-role {
  font-size: 12px;
  margin-top: 5px;
}
