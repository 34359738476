.loginForm {
  border-radius: 30px 30px;
  width: 500px;
  margin: auto;
  max-width: 100%;
  background: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.authenticateContainer {
  background: url("/assets/images/Background.svg") center no-repeat;
  // background: url('../../../public/assets/images/Background.svg');
  position: fixed;
  width: 100%;
  height: 100%;
  background-size: cover;
  left: 0;
  top: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.loginContent {
  overflow: hidden;
  padding: 3rem;
}
.formTitle {
  text-align: center;
}
img.infinityLogo {
  width: 70%;
}
 
.formTitle h2 {
  margin: 20px 0 20px;
}
.formTitle > h5 {
  font-weight: 300 !important;
  font-size: 1rem;
}
h5 {
  font-weight: 400;
  font-size: 1.2rem;
}
.form-row {
  margin-top: 30px;
  display: unset;
}
.form-group {
  position: relative;
  margin-top: 25px;
  margin-bottom: 0;
}

.form-group label {
  font-weight: 600;
  margin-bottom: 8px;
  display: block;
  font-size: 15px;
}

.formWrapper {
  /* display: flex; */
  /* position: relative; */
  /* align-items: center; */
  border-bottom: 2px solid #e6e6e6;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.formWrapper span {
  position: absolute;
  left: 0;
  top: 13px;
}

.formWrapper input {
  padding: 10px 10px 10px 30px;
  width: 100%;
}
.loginSubFooter {
  display: flex;
  align-content: center;
  justify-content: space-between;
  margin: 25px 0 10px;
  font-size: 15px;
}

input.check {
  margin-right: 10px;
  transform: scale(1.3);
}
.loginForm a {
  color: #004fa8 !important;
  text-decoration: none !important;
}
.form-label,
p span {
  color: #004fa8;
  cursor: pointer;
}
button.btn-submit-primary {
  background: #004fa8;
  color: #ffff;
  border: 0;
  height: 40px;
  width: 100%;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.18);
  border-radius: 5px;
  cursor: pointer;
}
.form-control.loginPage {
  border: 0;
  outline: 0;
  background: none;
}
.botIcon {
  display: flex;
  flex-direction: column;
  align-content: space-around;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  text-align: center;
}
.botIcon img {
  width:calc((360/16)*1rem);
}
.botIcon h5 {
  margin-top: 1.2rem;
}
.btn-allign {
  margin-top: 2rem;
}
.btn-allign p {
  margin-top: 30px;
  font-size: 15px;
  text-align: center;
}
h1.loginTitle {
  /* padding-left: 20px; */
  left: 120px;
  width: 100%;
  top: 537px;
  font-weight: 700;
  font-size: 2.6rem;
  margin-top: 1.5rem;
  margin-bottom: 0;
  letter-spacing: 2.15px;
  background: linear-gradient(90.03deg, #d10065 1.12%, #0057b9 99.35%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.remember-check-wrap {
  display: flex;
  align-items: center;
}

.input-icon {
  position: absolute;
  top: 50%;
  margin-top: -9px;
}

.email {
  margin-top: -7px;
}

@media screen and (max-width: 991px) {
  .authenticateContainer {
    overflow: auto;
  }
  .botIcon img {
    width: 60%;
  }
}
@media screen and (max-width: 767px) {
  h1.loginTitle {
    font-size: 2rem;
  }
  .botIcon {
    margin-bottom: 1rem;

    h5 {
      margin-top: 1rem;
    }
    img {
      width: 60%;
    }
  }
}
@media screen and (max-width: 575px) {
  .loginContent {
    padding: 1.5rem;
  }
  h1.loginTitle {
    font-size: 1.7rem;
    margin-top: 0.5rem;
  }
  .botIcon {
    img {
      width: 50%;
    }
    h5 {
      margin-top: 0.7rem;
    }
  }
}
